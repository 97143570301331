const InputText = ({ label, name, placeholder, value, handleChange, checkable }) => {
  return (
    <div className='input-box d-flex flex-column mt-3'>
      <div className={`color-gray-accent ms-1 mb-1 ${value ? '' : 'text-medium'}`}>{label}</div>
      <div className='d-flex justify-content-between align-items-top'>
        <input className='custom-input' name={name} placeholder={placeholder} value={value} onChange={handleChange} />
        {value && checkable && <i className='fa fa-circle-check check-indicator ms-2'></i>}
      </div>
    </div>
  )
}

export default InputText
