import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { LocalStorageManager } from '../util/localStorageManager'
import axios from 'axios'
import CustomButton from '../component/CustomButton'
import CustomItem from '../component/CustomItem'

const Home = () => {
  const [state, setState] = useState({ brands: 0, tenantId: null })
  const [loading, setLoading] = useState({ brands: 0, tenantId: null })
  const [open1, setOpen1] = useState(false)
  const toggle1 = () => setOpen1(!open1)
  const [open2, setopen2] = useState(false)
  const toggle2 = () => setopen2(!open2)

  useEffect(() => {
    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    if (store.tenantId) {
      setLoading(true)
      axios
        .post('/api/retrieveTenant', { tenantId: store.tenantId })
        .then((res) => {
          const brandlist = res.data.brands ?? []
          setState({ brands: brandlist.length, tenantId: store.tenantId })
          // if (brandlist.length === 0) {
          //   if (LocalStorageManager.getItem('hit') === 'showed') {
          //     return
          //   }
          //   toggle2()
          //   LocalStorageManager.setItem('hit', 'showed')
          // }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      toggle2()
    }
    // eslint-disable-next-line
  }, [])

  const navigate = useNavigate()
  const stepNext = (url) => {
    if (url !== '/my-page') {
      const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
      axios
        .get('/api/getStore/' + store._id)
        .then((res) => {
          if (!res.data.store && !res.data.user) {
            return
          }
          const store = { ...res.data.store, userId: res.data.user._id }
          LocalStorageManager.setObjectItem(LocalStorageManager.user, res.data.user)
          LocalStorageManager.setObjectItem(LocalStorageManager.store, store)
          if (!res.data?.user?.planStatus) {
            toggle1()
            return
          }
          navigate(url)
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'エラー')
          console.log(error)
          return
        })
    } else {
      navigate(url)
    }
  }

  const getStorename = () => {
    const store = LocalStorageManager.getObjectItem('store')
    return store.storeName
  }

  return (
    <div className='default-container bg-default m-auto'>
      <div className='grid-default-resize'>
        <div className='color-gray-accent mb-3 text-center text18' style={{ paddingTop: '42px' }}>
          {getStorename() ?? 'Salon call me'}
        </div>
        <div className='input-box bg-gray-main'>
          <div className='color-gray-main cursor-pointer py-2' onClick={() => stepNext('/accept-booking')}>
            <i className='fa-solid fa-comment-sms bg-white me-2 p-1 rounded-1'></i>
            <span className='text-white '>予約受付</span>
          </div>
        </div>
        <CustomItem text={'予約内容の送信履歴'} icon={'fa fa-clock-rotate-left'} handleClick={() => stepNext('/history')} />
        <CustomItem text={'予約一覧'} icon={'fa-regular fa-calendar'} handleClick={() => stepNext('/reservations')} />
        <CustomItem text={'マイページ'} icon={'fa fa-user'} handleClick={() => stepNext('/my-page')} />
        {state && (state.brands === 0 || !state.tenantId) && !loading && (
          <div className='input-box mt-5 cursor-pointer' onClick={toggle2} style={{ border: '1px solid #923A3A' }}>
            <div className='d-flex justify-content-between'>
              <span className='color-gray-main'>事前決済でキャンセルを防止！</span>
              <i className='fa fa-angle-right color-red'></i>
            </div>
            <div className='color-gray-main mt-2'>キャンセルを予防したい場合は、カード決済の利用をおすすめします。</div>
          </div>
        )}
      </div>
      <Modal isOpen={open1} toggle={toggle1} className='cancel-modal logout-modal' centered size='sm'>
        <ModalBody>
          <div className='d-flex flex-column align-items-center pt-3'>
            <div className='text-center color-gray-sub text20 mb-3'>プランの加入が必要です</div>
            <div className='color-gray-main  text-medium mb-4'>
              この機能はプランに加入している場合のみご利用いただけます。
              <br />
              プランの確認ページから加入いただけます。
            </div>
            <CustomButton text='プランの確認ページへ' handleOnClick={() => navigate('/plan-check')} />
            <div className='text-center color-gray  mt-3'>
              <span className='cursor-pointer' onClick={toggle1}>
                閉じる
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={open2} toggle={toggle2} className='alert-modal logout-modal' centered size='sm' fade={false} o>
        <ModalBody>
          <div className='d-flex flex-column m-2'>
            <div className='color-gray-accent my-3 text20 text-center'>{state.brands !== 0 ? '設定お疲れ様でした！' : '事前決済でキャンセル防止'}</div>
            <div className='color-gray-sub mb-4 text16 text-medium mx-3'>
              {state.brands !== 0 ? '審査が完了するとお客様に事前決済が可能な予約メッセージを送ることができます。' : '顧客が事前に与信枠を確保することで、キャンセルの防止につながります。'}
              <br></br>
              {state.brands !== 0 ? 'しばらくおまちください。' : 'そのためには、以下の設定を行ってください。'}
            </div>
            <div className='color-gray-font mb-1 text18'>
              <span className={`me-2 ${state.tenantId ? 'opacity-60' : ''}`}>1. 振り込み先登録</span>
              {state.tenantId && <i className='fa fa-circle-check check-indicator text20'></i>}
            </div>
            <div className={`color-gray-main mb-4 mx-3 text-medium ${state.tenantId ? 'opacity-60' : ''}`}>顧客が事前決済・カード決済した 施術費用の振込先を設定しましょう。</div>
            <div className='color-gray-font mb-1 text18'>
              <span className={`me-2 ${state.brands === 0 ? '' : 'opacity-60'}`}>2. 決済方法の設定</span>
              {state.brands !== 0 && <i className='fa fa-circle-check check-indicator text20'></i>}
            </div>
            <div className={`color-gray-main mb-4 mx-3 text-medium ${state.brands === 0 ? '' : 'opacity-60'}`}>
              顧客が使えるカードブランドを設定しましょう。
              <br></br>
              ※審査に数日かかります。
            </div>
            <div className='mb-2'></div>
            <CustomButton text={state.tenantId ? 'カード決済の登録へ' : '振り込み先の登録へ'} handleOnClick={() => navigate(state.tenantId ? '/card-check' : '/register-transfer-destination')} />
            <div className='text-center mt-3'>
              <span className='color-gray-sub  cursor-pointer' onClick={toggle2}>
                後で行う
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Home
