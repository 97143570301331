export const METHOD_TEXT = ['安心クレジットカード払い', '通常クレジットカード払い', '店舗で支払う']
export const POLICY_TEXT = ['当日キャンセルの場合は100%\n前日キャンセルの場合は50%', '当日キャンセルの場合は50%\n前日キャンセルの場合は0%', '当日キャンセルの場合は0%\n前日キャンセルの場合は0%']
export const BANK_ACCOUNT_TYPES = [
  { value: '普通', label: '普通' },
  { value: '当座', label: '当座' },
]

export const BOOK_STATUS = {
  booked: 'booked',
  checked: 'checked',
  verified: 'verified',
  payed: 'payed',
  canceled: 'canceled',
}

export const BRAND_STATUS = {
  passed: 'passed',
  review: 'in_review',
}
